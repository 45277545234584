<template>
    <div>
        <div class="class-body">
            <div :class="{
                'class-card':true,
                's-card':selectClass == item.key_name,
                'w-card':selectClass != item.key_name,
                'pointer':true
            }" v-for="item in classList" :key="item.category" @click="getClassName(item.key_name)">
                <p>{{item.key_name}}</p>
            </div>
        </div>
        <adContent></adContent>
        <div class="game-list">
            <div class="game-card pointer" v-for="item in gameList" :key="item.id" @click="goDetails(item.id)">
                <div class="card-img">
                    <img :src="item.logo" alt="">
                    <div class="play-btn">PLAY</div>
                </div>
                <div class="card-title">{{item.title}}</div>
                <div class="card-syn">{{item.synopsis}}</div>
            </div>
            <span v-for="item in 3" :key="item"></span>
        </div>
        <div class="game-blurb noselect">
            {{ blurbUrl }} is a revamped online gaming platform that leverages cutting-edge technology to deliver an unparalleled gaming experience. The platform features a curated selection of premium games, leveraging Adobe's advanced multimedia capabilities to provide visually stunning and highly interactive gameplay. From thrilling action adventures and puzzle-solving challenges to strategy games that test your intellect, Shockwave offers a diverse range of genres to suit every taste. The site's intuitive interface and seamless performance ensure that players can dive into their games without any hassle. Shockwave's active community fosters a sense of camaraderie, with players sharing tips, high scores, and game reviews. Regular updates and the addition of new games ensure that the platform remains fresh and exciting, keeping players engaged and coming back for more.
        </div>
    </div>
</template>

<script>
import navMenu from '@/utils/navMenu'
import { searchGame, getClassList, getGame } from "@/api/games"
import adContent from "@/components/adContent/index.vue";
    export default {
        components: {
            adContent
        },
        data() {
            return {
                gameList: [],
                classList: [],
                selectClass: 'New',
                blurbUrl: process.env.VUE_APP_BASE_GAME
            }
        },
        mounted() {
            let name = this.$route.params.searchtext;
            if(name){
                this.searchGame(name);
            }else{
                this.getList();
            }
            this.getClassList();
        },
        methods: {
            async searchGame(name){
                let res = await searchGame({q:name});
                this.gameList = res;
            },
            async getList(){
                let res = await getGame();
                this.gameList = res;
            },
            async getClassList(){
                let classlist = await getClassList();
                classlist.forEach(el => {
                    navMenu.forEach(item=>{
                        if(item.name==el.key_name){
                            el.url = item.url
                        }
                    })
                });
                this.classList = classlist;
            },
            async getClassName(category){
                this.selectClass = category;
                this.$router.push({
                    path: '/selectGame',
                    query: {
                        id: 'more',
                        key: category
                    }
                })
            },
            goDetails(id){
                this.$router.push({
                    path:'/details',
                    query:{
                        id
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.pointer{
    cursor: pointer;
}
.class-body{
    display: flex;
    margin-top: 54px;
    height: 50px;
    overflow: auto;
    .class-card{
        height: 49px;
        line-height: 49px;
        border-radius: 10px 10px 0 0;
        text-align: center;
        padding: 0 30px;
        box-sizing: border-box;
        p{
            font-family: Inter, Inter;
            font-weight: bold;
            font-size: 12px;
            color: #FFFFFF;
            font-style: normal;
            text-transform: none;
        }
    }
    .s-card{
        border-top: 1px solid #685AF5;
        border-left: 1px solid #685AF5;
        border-right: 1px solid #685AF5;
    }
    .w-card{
        border-bottom: 1px solid #685AF5;
    }
}
.game-list{
    margin-top: 42px;
    // width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 768px) {
        .game-card{
            width: 43%;
        }
    }
    @media (min-width: 768px) {
        .game-card{
            width: 202px;
        }
    }
    .game-card{
        background: #A19DFB;
        border-radius: 9px;
        padding: 10px 0 13px;
        box-sizing: border-box;
        margin: 10px 0;
        .card-img{
            margin: 0 auto;
            width: 100%;
            // height: 184px;
            border: 9px;
            position: relative;
            img{
                width: 100%;
                height: 184px;
            }
            .play-btn{
                background: #685AF5;
                width: 70px;
                height: 35px;
                line-height: 35px;
                border-radius: 5px;
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
        }
        .card-title{
            width: 100%;
            height: 18px;
            margin: 13px auto 18px;
            font-family: Inter, Inter;
            font-weight: bold;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 19px;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }
        .card-syn{
            width: 100%;
            margin: 0 auto;
            font-family: Inter, Inter;
            font-weight: 400;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 14px;
            text-align: left;
            font-style: normal;
            text-transform: none;

            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    span{
        width: 202px;
    }
}
.game-blurb{
    border-radius: 10px;
    padding: 8px 12px;
    box-sizing: border-box;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #FFF;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}
</style>