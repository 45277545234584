<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <headerNav></headerNav>
    <!-- <navMenu></navMenu> -->
    <router-view/>
    <footerMenu></footerMenu>
  </div>
</template>
<script>
import headerNav from "@/components/headerNav/index"
// import navMenu from '@/components/navMenu'
import footerMenu from '@/components/footer'
export default{
    components:{
      headerNav,
      // navMenu,
      footerMenu
    }
}
</script>
<style lang="less">
@media (min-width: 768px) {
  #app{
    width: 1440px;
    margin: 0 auto;
    padding: 50px 60px 0;
    box-sizing: border-box;
  }
}
@media (max-width: 768px) {
  #app{
    padding-top: 50px;
  }
}
body{
  background: #221A49;
}
*{
  padding: 0;
  margin: 0;
}
</style>
