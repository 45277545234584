<template>
    <div>
        <div class="detail">
            <div class="details-top" v-if="isPlay">
                <div class="top-body">
                    <div class="top-img">
                        <img :src="gameInfo.logo" alt="">
                    </div>
                    <div class="top-text">
                        <div class="top-title no-warp">
                            {{gameInfo.title}}
                        </div>
                        <div class="top-rat">
                            <van-rate
                            v-model="gameInfo.rating"
                            readonly
                            :size="20"
                            color="#FFC900"
                            void-icon="star"
                            />
                        </div>
                        <div class="play-num">
                            130K PLAY
                        </div>
                    </div>
                </div>
                
                <div class="top-btn" @click="()=>{isPlay = false}">
                    PLAY GAME NOW
                </div>                
            </div>
            <div class="gamewin" v-else>
                <iframe
                style="width:100%"
                :src="baseUrl+gameInfo.url"
                frameborder="0"></iframe>
            </div>
            <div class="details-description">
                <div class="des-top">
                    <span></span>
                    Description:
                </div>
                <div class="des-body warp-6">{{gameInfo.synopsis}}</div>
            </div>
            <div class="cont-st">
                <div class="cont-st-h">
                    <img src="@/assets/slices/ic_all1.png" alt="">
                    <p>ALL GAMES</p>
                    <!-- <div>more</div> -->
                </div>
                <div class="cont-st-card" v-for="item in gameList" :key="item.id" @click="goDetails(item)">
                    <img :src="item.logo" alt="">
                    <p class="no-warp">{{item.title}}</p>
                    <!-- <van-rate
                    v-model="value"
                    readonly
                    :size="10"
                    color="#FF3434"
                    void-icon="star"
                    void-color="#eee"
                    /> -->
                </div>
                <span v-for="item in 10" :key="item+'s'"></span>
            </div>
        </div>
    </div>
</template>

<script>
import { isMobile, scrollToTop } from "@/utils/api"
import { getGameById } from "@/api/games"

    export default {
        data() {
            return {
                value:4,
                gameList:[],
                gameInfo:{},
                spanNum:0,
                isPlay:true,
                baseUrl:''
            }
        },
        mounted() {
            let id = this.$route.query.id

            this.getGameInfo(id);

            if(isMobile()){
                this.spanNum = 3-2
            }else{
                this.spanNum = 11-2
            }

            // this.baseUrl = process.env.VUE_APP_BASE_GAME
            this.baseUrl = 'https://'+window.location.hostname

            scrollToTop(0);
        },
        methods: {
            async getGameInfo(id){
                let { game, similarGames } = await getGameById({id})
                this.gameInfo = game;
                this.gameList = similarGames;
                console.log(this.gameInfo);
            },
            getGame(){

            },
            goDetails(item){
                this.gameInfo = item;
                this.isPlay = true;
                scrollToTop(0);

            }
        },
    }
</script>

<style lang="less" scoped>
.detail{
    padding: 20px;
    box-sizing: border-box;
    .details-top{
        margin-bottom: 22px;
        .top-body{
            display: flex;
            justify-content: space-between;
            margin-bottom: 19px;
            .top-img{
                width: 127px;
                height: 127px;
                background: #000000;
                box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
                border-radius: 15px;
                border: 1px solid #000000;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .top-text{
                width: 189px;
                .top-title{
                    width: 189px;
                    height: 22px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    font-size: 16px;
                    color: #FFF;
                    line-height: 22px;
                    text-align: left;
                    font-style: normal;
                    margin-bottom: 18px;
                }
                .top-rat{
                    margin-bottom: 43px;
                }
                .play-num{
                    width: 189px;
                    height: 22px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    font-size: 16px;
                    color: #FFF;
                    line-height: 22px;
                    text-align: left;
                    font-style: normal;
                }
            }
        }
        .top-btn{
            margin: 0 auto;
            width: 342px;
            height: 45px;
            background: #F5D558;
            border-radius: 23px;
            border: 1px solid #000000;
            cursor: pointer;

            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
            line-height: 45px;
            text-align: center;
            font-style: normal;
        }
    }
    .gamewin{
        width: 100%;
        height: calc(100ch - 50px);
        iframe{
            width: 100%;
            height: 100%;
        }
    }
    .details-description{
        padding: 7px 11px;
        box-sizing: border-box;
        background: #F5D558;
        border-radius: 10px;
        margin-bottom: 35px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #000;
        text-align: left;
        font-style: normal;
        .des-top{
            font-size: 16px;
            line-height: 22px;
            display: flex;
            align-items: center;
            span{
                display: inline-block;
                width: 4px;
                height: 14px;
                background: #000;
                border-radius: 2px;
                margin-right: 4px;
            }
        }
        .des-body{
            font-size: 14px;
            line-height: 20px;
        }
    }
    .cont-st{
        // padding: 0 20px;
        box-sizing: border-box;
        border-radius: 20px;
        margin-bottom: 25px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .cont-st-h{
            width: 100%;
            height: 40px;
            // background: #504BA9;
            background: #F5D558;
            border-radius: 10px;
            border: 1px solid #000000;
            display: flex;
            align-items: center;
            // justify-content: space-between;
            img{
                width: 23px;
                height: 23px;
            }
            p{
                // width: 100%;
                // height: 40px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
                // line-height: 40px;
                text-align: left;
                font-style: normal;
                // margin: 15px 0 0 15px;
            }
            div{
                margin-right: 12px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 22px;
                text-align: left;
                font-style: normal;
                text-transform: uppercase;
            }
        }
        .cont-st-card{
            margin: 7px 0;
            // padding-top: 6px;
            box-sizing: border-box;
            width: 101px;
            // height: 152px;
            // background: #FFFFFF;
            // border-radius: 11px;
            text-align: center;
            img{
                width: 101px;
                height: 101px;
                border-radius: 15px;
            }
            p{
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                font-size: 14px;
                color: #FFF;
                line-height: 20px;
                text-align: left;
                font-style: normal;
            }
        }
        span{
            width: 101px;
        }
    }
    .no-warp{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .warp-6{
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>